<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-color: #e8f2fc; */
}

#app {
  height: 100%;
}

</style>
