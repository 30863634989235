<template>
    <div class="nav-container">
      <nav class="nav">
        <input type="radio" name="nav-item-opt" id="nav-item-opt-1" v-model="selectedNavItem" value="Text">
        <label class="nav-item" for="nav-item-opt-1" @click="updateNavItem('Text')">Text</label>
  
        <input type="radio" name="nav-item-opt" id="nav-item-opt-2" v-model="selectedNavItem" value="Pdf">
        <label class="nav-item" for="nav-item-opt-2" @click="updateNavItem('Pdf')">Pdf</label>
        <div class="tracker"></div>
      </nav>
    </div>
</template>

<script>
export default {
  props: {
    
  },
  data() {
    return {
      selectedNavItem:"Text"
    };
  },
  methods: {
    // 在选中的导航项更改时调用，记录当前选项并通知父组件
    updateNavItem(value) {
      this.selectedNavItem = value;
      this.$emit('update:selectedNavItem', this.selectedNavItem);
    }
  },
  watch: {
    // 当选中的导航项发生变化时，通知父组件新的值
    selectedNavItem(navItem) {
      this.$emit("update:selectedNavItem", navItem);
    }
  }
};
</script>
  
  <style scoped>
  /* 全局样式 */
* {
  padding: 0;
  margin: 0;
}

/* HTML和Body的样式 */
html, body {
  width: 20px; /* 宽度设定为20像素 */
  height: 20px; /* 高度设定为20像素 */
}

/* Body的布局和背景颜色 */
body {
  display: flex; /* 使用弹性盒子布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background: #eaecf0; /* 背景颜色设定为浅灰色 */
}

/* 导航栏样式 */
.nav {
  position: relative; /* 相对定位 */
  background: white; /* 背景颜色为白色 */
  border-radius: 24px; /* 边框圆角为24像素 */
  font-size: 0; /* 字体大小设为0 */
  padding: 4px; /* 填充为4像素 */
  z-index: 1; /* 层叠顺序为1 */
}

/* 导航项样式 */
.nav-item {
  width: 100px; /* 宽度设为100像素 */
  height: 32px; /* 高度设为32像素 */
  font-size: 14px; /* 字体大小设为14像素 */
  color: #5080ef; /* 字体颜色设为蓝色 */
  display: inline-flex; /* 行内弹性布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  cursor: pointer; /* 鼠标样式为指针 */
  transition: color .3s ease-in-out; /* 颜色变化过渡效果 */
}

/* 单选按钮隐藏 */
.nav > input[type="radio"] {
  width: 0;
  height: 0;
  opacity: 0; /* 透明度设为0，使其不可见 */
}

/* 选中的导航项样式 */
.nav > input[type="radio"]:checked + label {
  color: white; /* 选中时字体颜色为白色 */
}

/* 跟踪器样式 */
.tracker {
  width: 100px; /* 宽度设为100像素 */
  height: 32px; /* 高度设为32像素 */
  background: #5080ef; /* 背景颜色设为蓝色 */
  z-index: -1; /* 层叠顺序设为-1 */
  position: absolute; /* 绝对定位 */
  left: 4px; /* 左边距为4像素 */
  top: 4px; /* 上边距为4像素 */
  border-radius: 24px; /* 边框圆角为24像素 */
  transition: transform .3s ease-in-out; /* 变形过渡效果 */
}

/* 第二个选项选中时的跟踪器位置 */
#nav-item-opt-2:checked ~ .tracker {
  transform: translateX(calc(100px)); /* 水平移动100像素 */
}

/* 第三个选项选中时的跟踪器位置 */
#nav-item-opt-3:checked ~ .tracker {
  transform: translateX(calc(100px * 2)); /* 水平移动200像素 */
}
  </style>