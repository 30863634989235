const config = {
    // baseURL: 'http://38.6.223.161:8088',
    baseURL: 'http://localhost:8088',
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
}
  
export default config;
  