<template>
    <div class="block">
      <div class="box">
        <p class="number">
          <span class="num">{{ percentage }}</span>
          <span class="sub">%</span>
        </p>
        <p class="title">{{ title }}</p>
      </div>
      <span class="dots" :style="dotStyle"></span>
      <svg class="svg">
        <defs>
          <linearGradient id="gradientStyle">
            <stop offset="0%" stop-color="#565656"></stop>
            <stop offset="100%" stop-color="#b7b5b5"></stop>
          </linearGradient>
        </defs>
        <circle class="circle" cx="60" cy="60" r="55" :style="circleStyle"></circle>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CircularProgressBar',
    data() {
      return {
        title: 'test_power', // 进度条标题
        percentage: 0, // 初始进度值
      };
    },
    computed: {
      // 计算圆环的样式
      circleStyle() {
        const circumference = 2 * Math.PI * 55;
        const dashoffset = circumference - (circumference * (this.percentage / 100));
        return {
          strokeDasharray: `${circumference}`,
          strokeDashoffset: `${dashoffset}`,
        };
      },
      // 计算小点的样式
      dotStyle() {
        return {
          transform: `rotate(${360 * (this.percentage / 100)}deg)`,
          opacity: this.percentage === 100 ? 0 : 1,
        };
      },
    },
    mounted() {
      this.animateProgressBar();
    },
    methods: {
      animateProgressBar() {
        let count = 0;
        const target = 50; // 目标进度值
        const time = 2000 / target;
        const interval = setInterval(() => {
          if (count >= target) {
            clearInterval(interval);
          } else {
            count++;
            this.percentage = count;
          }
        }, time);
      },
    },
  };
  </script>


  
<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap'); */

/* 重置浏览器默认样式，并设置基本字体样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  list-style-type: none;
  text-decoration: none;
}

/* 设置 body 的布局，背景颜色等 */
body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #0ec0ec; /* 背景色，可根据页面布局调整 */
}

/* 主要的块元素，用于包含圆形进度条 */
.block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px; /* 减小尺寸 */
  height: 120px;
  border-radius: 50%;
}

/* 包含数字和标题的盒子样式 */
.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border-radius: 50%;
  background-color: #d6e4ff; /* 淡蓝色背景 */
  box-shadow: 0 0 5px 2px #b0c4de; /* 淡蓝色阴影 */
}

/* 盒子外边框样式 */
.box::before {
  position: absolute;
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  border: 0px solid #0e82ee;
}

/* 数字部分的样式 */
.box .number span {
  color: #4a4a4a; /* 深灰色，提高对比度和可读性 */
}

/* 主要数字的样式 */
.box .number .num {
  font-size: 25px;
  font-weight: bold;
}

/* 百分号样式 */
.box .number .sub {
  font-size: 20px;
}

/* 标题的样式 */
.box .title {
  font-size: 12px;
  font-weight: 600;
  color: #4a4a4a; /* 深灰色，提高对比度和可读性 */
}

/* 旋转小点的样式 */
.dots {
  display: block;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 2s transform, 2s opacity ease;
}

/* 小点的具体样式 */
.dots::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: 3px;
  left: 50%;
  border-radius: 50%;
  background-color: #adc6ff; /* 淡蓝色小点 */
  box-shadow: 0 0 5px 1px #b0c4de;
  transform: translateX(-50%);
}

/* SVG 元素样式 */
.svg {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: none;
  transform: rotate(-90deg);
}

/* 圆形路径的样式 */
.circle {
  stroke: #0e82ee;
  stroke-width: 6px;
  stroke-dasharray: 503;
  stroke-dashoffset: 503;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: 2s stroke-dashoffset;
}

</style>

  