<template>
    <div class="home">

        <div class="video-background">
            <video autoplay muted loop>
            <source src="../assets/bg.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </div>

        <div style="height: 100%;"></div>
        <div style="width: 1440px;height: 100%;">
            <div class="head">
                <span>GPT-Inspector: Text generated by Human or AI?</span>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px;margin-bottom: 30px;color: #3838f1;">
                <span class="head-text">Please input a sentence in English to classify whether it's
                    generated by a human or AI.</span>
            </div>
            <div class="context">
                <div class="main">
                    <div class="main-left">
                        <div class="main-select">
                            <div>
                                <span class="model-text">model：</span>
                                <el-tag class="model-lable" @click="changeLabel(index)" v-for="(item, index) in modelLables"
                                    :key="index" effect="dark" :class="{ 'lable-checked': modelIndex === index }">
                                    {{ item.label }}
                                </el-tag>
                            </div>
                            <div>
                                <my-switch :selected-nav-item.sync="type"></my-switch>
                            </div>
                        </div>
                        <div class="main-content" style="margin: 30px 30px 10px 5px;" v-loading="loading"
                            element-loading-text="数据分析中，请稍后" element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <div class="main-input" v-show="isClickText()">
                                <el-input class="main-input-el" type="textarea" placeholder="请输入内容"
                                    v-model="text" resize="none">
                                </el-input>
                            </div>
                            <div class="upload" v-show="!isClickText()">
                                <!-- <div>
                                    <el-upload drag action="http://localhost:8088/api/detector/upload" multiple>
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    </el-upload>
                                </div> -->
                                <div>
                                    <button class="new-button">
                                        <div v-if="!fileLoading">
                                            <div >
                                                <i class="el-icon-upload" 
                                                style="margin-right: 5px;font-size: 60px;color: #c0c4cc;"></i>
                                            </div>
                                            <div style="color: #409eff;">
                                                <span>点击上传文件</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div >
                                                <i class="el-icon-loading" 
                                                style="margin-right: 5px;font-size: 60px;color: #c0c4cc;"></i>
                                            </div>
                                            <div style="color: #409eff;">
                                                <span>文件上传中</span>
                                            </div>
                                        </div>
                                        <input type="file" class="file-input" @change="handleFileChange" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="main-footer">
                            <span class="count-text">{{ countText }} words</span>
                            <div>
                                <button class="footer-btn" @click="getExample()">
                                    样例
                                </button>
                                <button class="footer-btn" @click="clearText()">
                                    清空文本
                                </button>
                                <button class="footer-btn" @click="anlaData()">
                                    {{ isClickText() ? '文本分析' : 'PDF分析' }}
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="main-show">
                        <div class="main-show-top">
                            <div class="c-load">
                                <circle-load></circle-load>
                            </div>
                            <div class="main-message">
                                <span>全文共{{ message.total }}个句子</span>
                                <span>通过AI生成了<label style="color: blue;">{{ message.ai }} </label>个</span>
                            </div>
                        </div>
                        <hr class="divider" />
                        <div class="main-show-bottom">
                            <div>
                                <div class="console-text" v-for="(item, index) in consoleText" :key="index">
                                    <span> {{ item.text }} </span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="image-container">
                <img :src="imageSrc" class="responsive-image" />
            </div>
        </div>
        <div style="height: 100%;"></div>

    </div>
</template>
  
<script>
import MySwitch from './common/Switch.vue';
import CircleLoad from './common/CircleLoad.vue';

export default {
    name: 'Home',
    components: {
        'my-switch': MySwitch,
        'circle-load': CircleLoad
    },
    data() {
        return {
            selectedFile: null,
            fileLoading: false,
            imageSrc: require('@/assets/image1.png'),
            vsImageSrc: require('@/assets/vs.jpg'),
            modelIndex: 0,
            modelLables: [
                { label: 'ChatGPT' },
                { label: 'GPT-4' }
            ],
            type: "Text",
            text: "",
            message: {
                total: 100,
                ai: 50,
            },
            loading: false,
            consoleText: [
                {
                    "id": 1,
                    "text": "The gentle rustling of leaves, the symphony of birdsong, and the dappled sunlight filtering through the towering trees create a serene tapestry of nature's beauty.",
                },
                {
                    "id": 2,
                    "text": "A walk in the woods is a timeless and profound experience, one that invites us to disconnect from the hustle and bustle of modern life and reconnect with the natural world.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                },
                {
                    "id": 2,
                    "text": "A walk in the woods is a timeless and profound experience, one that invites us to disconnect from the hustle and bustle of modern life and reconnect with the natural world.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                },
                {
                    "id": 2,
                    "text": "A walk in the woods is a timeless and profound experience, one that invites us to disconnect from the hustle and bustle of modern life and reconnect with the natural world.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                },
                {
                    "id": 3,
                    "text": "It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth.",
                }
            ]
        }
    },
    methods: {
        isClickText() {
            return this.type === 'Text';
        },
        changeLabel(index) {
            this.modelIndex = index;
        },
        anlaData() {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 3000);
        },
        getExample() {
            this.text = "The gentle rustling of leaves, the symphony of birdsong, and the dappled sunlight filtering through the towering trees create a serene tapestry of nature's beauty. A walk in the woods is a timeless and profound experience, one that invites us to disconnect from the hustle and bustle of modern life and reconnect with the natural world. It is a journey that nourishes the soul, rejuvenates the spirit, and reminds us of our deep-rooted connection to the earth";

        },
        clearText() {
            this.text = "";
        },
        //文件上传切换
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;   // 存储选中的文件
                this.uploadFile()
            } else {
                this.$message.error('文件上传失败');
            }
        },
        //文件上传
        uploadFile() {
            // 使用全局 apis 实例进行文件上传
            this.apis.uploadFile(this.selectedFile).then(response => {
                this.$message.success('文件上传成功');
                console.log('上传成功:', response);
            }).catch(error => {
                this.$message.error('文件上传失败');
                console.error('上传失败:', error);
            }).finally(() => {

            });
            
        },
    },
    computed: {
        countText() {
            let total = 0;
            // 统计英文单词个数
            const englishWordMatches = this.text.match(/\b[a-zA-Z]+\b/g);
            total += englishWordMatches ? englishWordMatches.length : 0;
            // 统计中文字数
            const chineseCharacterMatches = this.text.match(/[\u4e00-\u9fa5]/g);
            total += chineseCharacterMatches ? chineseCharacterMatches.length : 0;
            // 统计数字个数
            const numberMatches = this.text.match(/\d+/g);
            total += numberMatches ? numberMatches.length : 0;
            // 返回所有元素的总和
            return total;
        }
    },
    watch: {
        type(newVal) {
            console.log('Type changed to:', newVal);
        }
    }
}
</script>
  
<style scoped >
.home {
    /* 使用视口高度确保占满整个屏幕 */
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    flex-direction: column;
    /* 子元素沿列排列 */
    justify-content: space-between;
    /* 确保子元素分布在容器的两端 */
    /* background-color: #e8f2fc; */
}

.video-background {
  position: fixed;  /* 保持固定定位 */
  top: 0;          /* 定位到顶部 */
  left: 0;         /* 定位到左边 */
  width: 100%;     /* 宽度为100% */
  height: 100%;    /* 高度为100% */
  z-index: -1;     /* 确保视频在内容的后面 */
}

video {
  position: relative; /* 添加相对定位 */
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持宽高比 */
}

.title {
    display: flex;
    flex-direction: column-reverse;
    /* 子元素颠倒顺序 */
    align-items: center;
    /* 水平居中 */
    justify-content: center;
    /* 垂直对齐到底部 */
    width: 100%;
    height: 50px;
    /* 容器高度 */
    background-color: #FFFFFF;
}

.divider {
    border: none;        /* 去掉默认边框 */
    height: 1px;         /* 线的高度 */
    background-color: #ddd; /* 线的颜色 */
}

.main-show-bottom {
    width: 100%;
    /* 自定义的宽度 */
    height: 400px;
    padding: 5px;
    overflow-y: auto;
    box-sizing: border-box;
    margin-top: 10px;
}

.console-text {
    font-size: 13px;
    background-color: rgb(182, 233, 254);
    padding: 10px; /* 调整文字与边框的距离 */
    border: 1px solid rgb(51, 153, 255); /* 调整边框颜色 */
    border-radius: 5px; /* 圆角 */
    margin-bottom: 10px;
}

.image-container {
    position: fixed;
    /* 使用固定定位 */
    top: 0;
    /* 定位到底部 */
    left: 0;
    /* 左侧对齐到中心点 */
    margin-left: 180px;
    transform: translateX(-50%);
    /* 向左平移容器宽度的一半来居中 */
}

.image-container img {
    display: block;
    /* 防止图片下方出现空隙 */
    max-width: 100%;
    /* 可选，确保图片不会超出容器宽度 */
}

.responsive-image {
    max-width: 100%;
    /* 确保图片不超过容器宽度 */
    height: 50px;
    /* 保持图片的原始长宽比 */
}

.head {
    /* background: linear-gradient(180deg, #d1d1d3,#3ebaeb);
    -webkit-background-clip: text;
    color: transparent; */
    color: #ffffff;
    font-size: 35px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: 800;
    height: 60px;
}

.head-text {
    font-size: 16px;
    font-weight: 400;
    /* background: linear-gradient(180deg, #d1d1d3,#3ebaeb);
    -webkit-background-clip: text;
    color: transparent; */
    color: #ffffff;
}

.context {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
}

.main {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    /* box-shadow: inset 0 0 0 1px #8ed1fc; */
    background-color: #f9fbfe;
    border-radius: 22px;
    border: 2px solid #000;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.model-text {
    color: #c3c7d6;
    font: 10px;
    font-weight: bold;
}

.count-text {
    color: #c3c7d6;
    font-size: 10px;
    font-weight: bold;
    margin-top: 0%;
}

.main-input {
    height: 100%;
    width: 98%;
}

.main-input-el {
    height: 100%;
    width: 100%;
}
/* 
.main-input /deep/ .el-input__inner{
    height: 100%;
    width: 100%;
}
*/


.main-input-el ::v-deep .el-textarea__inner {
    height: 100% !important;
}

.upload {
    height: 100%;
    /* 父级的全高 */
    width: 98%;
    /* 父级的全宽 */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.new-button{
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 360px;
    height: 180px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-size: 14px;
}
.new-button:hover{
    border: 1px dashed #6db2ea;
}

.file-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto; /* 调整为自动宽度 */
    height: 100%; /* 调整高度以匹配按钮 */
    opacity: 0;
    cursor: pointer;
  }

.footer-btn {
    margin: 0 10px 0 0;
    height: 20px;
    background: #5080ef;
    border-radius: 999px;

    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 18px;
    width: 100px;
    height: 30px;
    word-break: break-word;
    border: 0;
}

.footer-btn:hover {
    box-shadow: #00001d 0 5px 20px -11px;
    color: #ffffff;
}


.model-lable {
    margin-right: 10PX;
    background-color: #edf6ff;
    color: #7881a0;
    font-weight: bold;
    border: 0px solid #edf6ff;
    border-radius: 10px;
    cursor: pointer;
    /* 设置鼠标光标为手指 */
}

.lable-checked {
    background: linear-gradient(to right, #bdd5ee, #96c5f3);
    color: #2c2e36;
    border-radius: 10px;
}

.model-lable:hover {
    background: linear-gradient(to right, #bdd5ee, #96c5f3);
    color: #2c2e36;
    border-radius: 10px;

}

.main-left {
    flex: 1;
    height: 550px;
    padding: 10px;
    width: 50%;
}

.main-select {
    display: flex;
    justify-content: space-between;
}

.main-content {
    height: 420px;
    width: 100%;
    border-radius: 10px;
}

.main-footer {
    display: flex;
    justify-content: space-between;
    height: 10%;
    margin-top: 10px;
}

.main-show {
    flex: 1;
    height: 550px;
    padding: 10px;
    width: 50%;
}

.main-show-top {
    display: flex;
    margin-left: 20px;

}

.c-load {}

.main-message {
    margin-top: 30px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-items: self-start;
    margin-left: 50px;
}

.vs-image-div {
    width: 40%;
}

.vs-image {
    display: block;
    /* 防止图片下方出现空隙 */
    max-width: 100%;
    margin-bottom: 10px;
}</style>
  