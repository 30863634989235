import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css';

import apis from './utils/request/http';

Vue.config.productionTip = false

Vue.prototype.apis = apis;
Vue.use(ElementUI) //使用elementUI

new Vue({
  render: h => h(App),
}).$mount('#app')
